<template>
  <div class="siffaPageBottom">
    <div class="content_area">
      <div v-if="isIndex" class="isIndex">
        <!-- <p class="friendLink">{{ $t("links") }}：</p> -->
        <ul class="link_box">
          <li
            v-for="(item, i) in Links_arr"
            :key="i"
            @click="link_click(item)"
            class="clearfix"
          >
            <a :href="item.href" target="_blank" rel="nofollow">
              <img :src="item.url" alt="" fit="contain" />
            </a>
          </li>
        </ul>
      </div>
      <div class="contact_follow">
        <div>
          <div class="contact">
            <h2>{{ $t("meetingConcats") }}</h2>
            <ul>
              <li>
                协会地址：石家庄市新华区泰华街29号天林大厦1512室
              </li>
              <li>
                邮箱：hebifa@hebifa.com
              </li>
              <li>
                电话：0311-87708676
              </li>
            </ul>
            <ul class="ul">
              
              <template>
                
              </template>
            </ul>
          </div>
        </div>
        <!-- 关注我们 -->
        <!-- <div class="follow">
          <h2>{{ $t("followUs") }}</h2>
          <ul>
            
          </ul>
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <bottomContent></bottomContent>
    </div>
  </div>
</template>

<script>
import bottomContent from "~jzs/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      isIndex: false,
      Links_arr: [],
      QRcode_arr: [
        {
          name: this.$t("thePublic"),
          url: "",
        },
        {
          name: this.$t("applets"),
          url: "",
        },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
  watch: {
    $route(val) {
      if (val.path == "/") {
        this.isIndex = true;
      } else {
        this.isIndex = false;
      }
    },
  },
};
</script>

<style  scoped lang="less">
.siffaPageBottom {
  // position: relative;
  width: 100%;
  height: 345px;
  background: #2F3646;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #999;
  }
  .link_box {
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      margin-right: 24px;
      // width: 64px;
      // height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-bottom: 10px;
    }
    margin-bottom: 10px;
  }
  .contact_follow {
    height: 244px;
    padding-top: 42px;
    display: flex;
    justify-content: space-between;
    h2 {
      height: 22px;
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #fff;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 535px;
      // height: 240px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 24px;
        }
      }
      .contactTwo {
        padding-top: 22px;
      }
    }
    .follow {
      width: 300px;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          div {
            color: #666666;
          }
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 54px;
    border-top: 1px solid #ececec;
    color: #666;
    font-size: 12px;
  }
}
</style>