<template>
  <div id="siffaApp">
    <component :is="layout">
      <router-view />
    </component>
    <el-dialog
      :visible.sync="showDialog"
      width="440px"
      class="login_dialog"
      :append-to-body="true"
      @close="showDialog = false"
    >
      <loginDialog @loginSuccess="loginSuccess"></loginDialog>
    </el-dialog>
    <el-dialog :visible.sync="infomationDialog" width="398px">
      <mationDialog></mationDialog>
      <div class="dialog-footer tc">
        <el-button type="default" size="small" @click="infomationDialog = false"
          >{{$t('cancel')}}</el-button
        >
        <el-button type="primary" size="small"
          ><a href="/console/talent/resume" @click="changeMation" 
            >{{$t('toBePerfect')}}</a
          ></el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import defaultlayout from "./layouts/default.vue";
import signlayout from "./layouts/sign.vue";
import loginDialog from "~jzs/components/common/loginDialog";
import baseConsolelayout from "~bac/layouts/default.vue";
import emptylayout from "./layouts/empty.vue";
import mationDialog from "~jzs/components/common/mationDialog";
export default {
  components: {
    defaultlayout,
    signlayout,
    loginDialog,
    baseConsolelayout,
    emptylayout,
    mationDialog,
  },
  data() {
    return {
      default_layout: "default", //设置layout
      showDialog: false,
      infomationDialog: false,
    };
  },
  mounted() {
    this.$GLOBALEVENT.$on("SHOWGLOBALLOGIN", (e) => {
      if (e) {
        this.eventCallback = e.waitTodo;
      }
      this.showDialog = true;
    });
    this.$GLOBALEVENT.$on("CLOSEGLOBALLOGIN", (e) => {
      this.showDialog = false;
    });
    this.$GLOBALEVENT.$on("SHOWIMPROVERESUME", (e) => {
      this.infomationDialog = true;
    });
  },
  methods: {
    loginSuccess() {
      this.showDialog = false;
      if (this.eventCallback) {
        this.$nextTick(() => {
          this.eventCallback && this.eventCallback();
        });
      }
    },
    changeMation() {
      this.infomationDialog = false;
      // this.$router.push({
      //   path: "/console/talent/resume",
      // });
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "layout";
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  a {
    color: #fff;
  }
}
</style>
