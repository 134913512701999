<template>
  <div class="navMenu">
    <div class="navMenuLeft">
      <div class="triangle"></div>
    </div>
    <div class="navContent">
      <div class="content_area">
        <div class="logo">
          <img :src="logoUrl" alt="">
        </div>
        <div class="topHeader">

          <div class="routerLink fr">
            <div v-if="!IS_LOGIN">
              <button @click="sign" class="sign">登录</button>
              <button @click="signUp" class="signUp">注册</button>
            </div>
            <span v-else>
              <el-popover placement="bottom-start" trigger="click">
                <div class="navMenuselfAccountInfo">
                  <p class="textOverflow">
                    {{ $t("name") }}：{{
                      USER_INFO.name_en
                      | priorFormat(USER_INFO.name_zh, LOCALE)
                    }}
                  </p>
                  <p class="textOverflow">
                    {{ $t("position") }}：{{
                      USER_INFO.job_title_en
                      | priorFormat(USER_INFO.job_title_zh, LOCALE)
                    }}
                  </p>
                  <p class="textOverflow">
                    {{ $t("email") }}：{{ USER_INFO.email | textFormat }}
                  </p>
                  <p class="textOverflow">
                    {{ $t("phone") }}：{{ USER_INFO.mobile | textFormat }}
                  </p>
                </div>

                <div class="navMenuselfAccountInfoBtn">
                  <el-button type="danger" size="small" @click="
                    logOut();
                  ">{{ $t("logout") }}</el-button>
                </div>
                <img :src="USER_INFO.avatar || defaultAvatar" :alt="$t('siffa')" class="userAvatar cursor"
                  slot="reference" />
              </el-popover>
              <router-link style="color:#fff;line-height: 60px; vertical-align:middle" to="/console/account/userInfo">{{
                $t("console")
              }}</router-link>
            </span>
          </div>
          <div class="search fr">
            <el-autocomplete size="mini" v-model="searchVal" :fetch-suggestions="querySearchCompanyName"
              :placeholder="$t('entercontent')" :trigger-on-focus="false">
              <i slot="suffix" class="el-icon-search cursor" style="margin-right: 5px"
                @click="searchContent"></i></el-autocomplete>
          </div>
        </div>
        <div class="navMenuLink">
          <div class="box fr">
            <div class="clearfix">
              <div class="meau">
                <el-menu class="el-menu-atf" mode="horizontal" :default-active="activeIndex" menu-trigger="click"
                  @select="selectMenu" unique-opened>
                  <el-menu-item index="/">首页</el-menu-item>
                  <el-menu-item index="/survey">协会概况</el-menu-item>
                  <el-menu-item index="/activitys?content_type=4">政策法规</el-menu-item>
                  <el-menu-item index="/activitys?content_type=92">行业培训</el-menu-item>
                  <el-menu-item index="/overseas">直通海外</el-menu-item>
                  <el-submenu index="/1">
                    <template slot="title">人才专区</template>
                    <el-menu-item index="/talents/recruitment">招聘信息</el-menu-item>
                    <el-menu-item index="/talents/library">人才专区</el-menu-item>
                  </el-submenu>
                  <el-submenu index="/2">
                    <template slot="title">会员名录</template>
                    <el-menu-item index="/companyCatalog">企业名录</el-menu-item>
                    <el-menu-item index="/companyContactList">联系人列表</el-menu-item>
                  </el-submenu>
                  <el-submenu index="/3">
                    <template slot="title">专项活动</template>
                    <el-menu-item
                      index="http://111.61.72.175:7191/xlsys/xlr.client.hdxh/build/index.html#/Login">行业统计</el-menu-item>
                    <el-menu-item index="/downloadSection">资料下载</el-menu-item>
                    <el-menu-item index="/activitys?content_type=91">协会通知</el-menu-item>
                    <el-menu-item index="https://ecomp.mofcom.gov.cn/loginCorp.html">企业备案</el-menu-item>
                  </el-submenu>
                </el-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navMenuRight">
      <div class="top"></div>
    </div>
    <el-dialog title="提示" :visible.sync="isMumber" width="300px" :close-on-click-modal="true">
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchCompany from "~jzs/components/company/unit/searchCompany";
export default {

  components: { searchCompany },
  data() {
    return {
      isMumber: false,
      activeIndex: "/",
      searchVal: "",
      logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/nav_logo.png',
      zhReg: /^[\u4e00-\u9fa5]+$/i,
    };
  },
  methods: {
    sign() {
      this.$router.push("/sign");
    },
    signUp() {
      this.$router.push("/signUp")
    },
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    selectMenu(index, path) {
      if (index.indexOf("http") !== -1) {
        window.open(index);
        return;
      }

      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true;
          return;
        }
      }
      this.$router.push(index);
    },
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    goToMember() {
      // this.isMumber = false;
      // this.$router.push("/memberShip");
    },

    async querySearchCompanyName(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.source = this.PJSource;
      params.usage = 2;
      params.is_vip = 1;
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.fullPath);
      // if (this.$route.query.parameter) {
      //   this.searchVal = this._decode(nv.query.parameter).searchVal;
      // } else {
      //   this.searchVal = "";
      // }

    },

  },
  mounted() { },
};
</script>

<style scoped lang="less">
.navMenu {
  height: 120px;
  width: 100%;
  position: relative;

  .navMenuLeft {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 120px;
    background-color: #e0e1f5;
    // .triangle {
    //   width: 30px;
    //   height: 120px;
    //   background-color: #beccf4;
    //   clip-path: polygon(0% 0%,100% 0%,0% 100%);
    // }
  }

  .navMenuRight {
    position: absolute;
    right: 0;
    top: 0;
    height: 120px;
    width: 50%;
    background-color: #0E17AE;

    .top {
      height: 60px;
      background-color: #0E17AE;
      border-bottom: 1px solid #2B34C2;
    }
  }

  .navContent {
    width: 100%;
    height: 120px;
    position: absolute;
    top: 0px;
    left: 0px;

    .content_area {
      position: relative;
      z-index: 99;

      .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 5;
        width: 207px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .topHeader {
        height: 60px;
        background-color: #0E17AE;
        border-bottom: 1px solid #2B34C2;
        z-index: 99;

        .search {
          line-height: 60px;
          margin-right: 68px;
        }

        .routerLink {
          height: 60px;
          width: 156px;

          .sign {
            width: 74px;
            height: 32px;
            background: rgba(74, 79, 160, 0);
            border: 1px solid #6e74ce;
            border-radius: 16px;
            color: #fff;
            margin: 14px 8px 0 0;
          }

          .sign:hover {
            background: #868bd6;
            border: 1px solid #1e29c4;
            color: rgba(255, 255, 255, 0.637);

          }

          .signUp {
            width: 74px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            opacity: 0.8;
            border-radius: 16px;
          }

          .signUp:hover {
            background: #868bd6;
            border: 1px solid #1e29c4;
            color: rgba(255, 255, 255, 0.637);
          }

          .navMenuselfAccountInfo {
            img {
              width: 34px;
            }
          }
        }
      }

      .navMenuLink {
        height: 60px;
        background-color: #0E17AE;

        .box {
          width: 993px;
        }
      }
    }
  }
}

.el-menu {
  background: transparent;
}

/deep/.el-menu--horizontal {
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;

}

/deep/.el-submenu__title {
  font-size: 18px;
  font-family: Microsoft YaHei;
}

/deep/.el-menu-item {
  background-color: transparent !important;
  color: #A8AAC9 !important;
  font-size: 18px;
  font-family: Microsoft YaHei;
}

/deep/.el-menu-item:hover {
  outline: 0 !important;
  color: #fff !important;
}

/deep/.el-menu-item.is-active {
  color: #fff !important;
  background: transparent !important;
}

/deep/.el-menu--horizontal>.el-submenu .el-submenu__title,
.el-menu--horizontal>.el-menu-item {
  border-bottom: none;

  &.is-active {
    border-bottom: 0;
    background: transparent;
  }
}

/deep/.el-menu--horizontal>.el-submenu {
  .el-submenu__title {
    color: #A8AAC9 !important;
    border-bottom: none !important;

    &:hover {
      background: transparent;
      color: #fff !important;
    }
  }
}

/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  color: #fff !important;
}

/deep/.el-button--danger {
  color: #fff;
  background-color: #1D2388 !important;
  border-color: #1D2388 !important;
}

/deep/.el-button--danger:hover {
  color: #fff;
  background-color: #4A4DA0 !important;
  border-color: #4A4DA0 !important;
}

/deep/.el-input--mini .el-input__inner {
  width: 221px;
  height: 32px;
  border-radius: 16px;
  opacity: 0.5;
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
  text-align: center;
}

.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }

  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}

.userAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.blue {
  color: #409eff;
  cursor: pointer;
}

.openDialogs {
  height: 100px;
  line-height: 100px;
}

.language {
  position: relative;
  z-index: 10;
  float: right;
  width: 60px;
  height: 40px;
  margin-left: 20px;
  line-height: 40px;

  /deep/ .el-dropdown {
    span {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>