<template>
  <div class="bottom_content">
    <p class="site">
      <span>© 2006- 2023 河北省国际货运代理协会</span>
      <img
        class="gonganImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/gongan.png"
      />
       <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow"> 冀ICP备12022657号-1 </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "commonBottomContent",
  serverCacheKey: () => "commonBottomContentV0.0.0",
};
</script>

<style lang="less" scoped>
.bottom_content {
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  .gonganImg {
    width: 14px;
    height: 14px;
    margin-top: -5px;
    margin-left: 10px;
  }
  p {
    &.noMargin {
      margin-top: 8px !important;
    }
    color: #666;
    a {
      color: #666;
    }
  }
}
</style>
